<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card title="Update Coupon">
        <b-form>
          <b-row>
            <!-- Start Date -->
            <b-col md="6">
              <b-form-group label="Start Date" label-for="start date">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="couponData.startdate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End Date -->
            <b-col md="6">
              <b-form-group label="End Date" label-for="end date">
                <validation-provider
                  #default="{ errors }"
                  name="end date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="couponData.enddate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- startprice -->
            <b-col md="6">
              <b-form-group label="Start Price" label-for="start price">
                <validation-provider
                  #default="{ errors }"
                  name="start price"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    id="start-price"
                    v-model="couponData.startprice"
                    :state="errors.length > 0 ? false : null"
                    placeholder="start price "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- endprice -->
            <b-col md="6">
              <b-form-group label="End Price" label-for="end price">
                <validation-provider
                  #default="{ errors }"
                  name="start price"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    id="end-price"
                    v-model="couponData.endprice"
                    :state="errors.length > 0 ? false : null"
                    placeholder="end price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="typeSelect"
                    :state="typeSelect == null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- max amount -->
            <b-col md="6">
              <b-form-group label="Amount" label-for="amount">
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    min="1"
                    id="amount"
                    type="number"
                    v-model="couponData.amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- max amount -->
            <b-col md="6">
              <b-form-group label="Max Amount" label-for="max amount">
                <validation-provider
                  #default="{ errors }"
                  name="max amount"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    min="1"
                    id="max_amount"
                    type="number"
                    v-model="couponData.max_amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter max amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- usernumbers -->
            <b-col md="6">
              <b-form-group label="User numbers" label-for="user numbers">
                <validation-provider
                  #default="{ errors }"
                  name="usern umbers"
                  rules="required|integer"
                >
                  <b-form-input
                    id="usernumbers"
                    v-model="couponData.usernumbers"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Code -->
            <b-col md="6">
              <b-form-group label="Coupon Code" label-for="Coupon Code">
                <b-form-input
                  min="1"
                  id="code"
                  type="text"
                  v-model="couponData.code"
                  placeholder="enter code"
                />
              </b-form-group>
            </b-col>
            <!-- include -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="coupon type"
                rules="required"
              >
                <b-form-group
                  label="Coupon Type"
                  label-for="Coupon Type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="include"
                    v-model="includeSelect"
                    :state="includeSelect == null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="includeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!--submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-row v-if="showVandB">
        <b-col cols="12">
          <b-card title="Products">
            <b-badge v-if="includedVlaue" variant="primary">
              Exploded Items
            </b-badge>
            <b-badge v-else variant="primary">
              included Items
            </b-badge>
            <b-row>
              <b-col cols="12">
                <div style="position: relative;">
                  <b-row>
                    <b-col cols="7">
                      <b-form-input
                        id="searchText"
                        class="mt-2"
                        style="height: 37.45px;"
                        type="search"
                        :state="couponData.variants.length == 0 ? false : true"
                        v-model="searchText"
                        placeholder="Search For Add Product"
                      />
                    </b-col>
                    <b-col cols="2">
                      <b-button
                        @click="searchResult(searchText)"
                        class="mt-2"
                        variant="outline-primary"
                        >Search</b-button
                      >
                    </b-col>
                    <b-alert
                      variant="warning"
                      style="margin: 0.438rem 1rem;"
                      :show="noResult"
                    >
                      <div class="alert-body">
                        No Result Found.
                      </div>
                    </b-alert>
                    <b-col
                      class="mt-2"
                      style="
                        position: absolute;
                        z-index: 9999;
                        padding: 0.438rem 1rem;
                        top: 35px;
                        height: 380px !important;
                        overflow: scroll;
                        scroll-padding: 0px !important;
                        padding-right: 0;
                      "
                      v-if="resultShow"
                      cols="12"
                    >
                      <b-list-group>
                        <b-list-group-item
                          @click="addProduct(item.id, item.name, item.price)"
                          v-for="(item, index) in productsSR"
                          :key="index"
                        >
                          <feather-icon icon="" class="mr-75" />
                          <p style="font-weight: 600;">{{ item.name }}</p>
                          <small style="padding-left: 10px;"
                            >Price: {{ item.price }}</small
                          >
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                    <b-col cols="12">
                      <b-row>
                        <b-col
                          v-if="couponData.variants.length"
                          class="pt-1 pb-1 pr-5 pl-5"
                          cols="12"
                        >
                          <h2
                            v-if="couponData.variants.length"
                            class="mr-3 pt-2"
                          >
                            Items List:
                          </h2>
                          <!-- Row Loop -->
                          <b-row
                            v-if="couponData.variants.length"
                            v-for="(item, index) in couponData.variants"
                            :key="index"
                          >
                            <b-col cols="12">
                              <hr />
                            </b-col>
                            <b-col class="pl-5" cols="7">
                              <h4>{{ item.name }}</h4>
                              <small style="padding-left: 10px;"
                                >Price: {{ item.price }}</small
                              >
                            </b-col>
                            <!-- Remove Button -->
                            <b-col md="3">
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mt-0 mt-md-1"
                                @click="removeItemProduct(index)"
                              >
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span>Remove</span>
                              </b-button>
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- <b-col cols="12" >
                            <b-card title="Bundles">
                                <b-badge v-if="includedVlaue" variant="primary">
                                    Exploded Bundles
                                </b-badge>
                                <b-badge v-else variant="primary">
                                    included Bundles
                                </b-badge>
                                <b-row>
                                    <b-col cols="12">
                                        <div style="position: relative">
                                            <b-row>
                                                <b-col cols="7">
                                                    <b-form-input id="searchText" class="mt-2" style="height:37.45px;" type="search" :state="couponData.variants.length == 0 ? false : true" v-model="searchText2" placeholder="Search For Add Bundle" />
                                                </b-col>
                                                <b-col cols="2">
                                                    <b-button @click="searchResultBundle(searchText2)" class="mt-2" variant="outline-primary">Search</b-button>
                                                </b-col>
                                                <b-alert variant="warning" style="margin: 0.438rem 1rem;" :show="noResultBund">
                                                    <div class="alert-body">
                                                        No Result Found.
                                                    </div>
                                                </b-alert>
                                                <b-col class="mt-2" style="position:absolute;z-index: 9999; padding: 0.438rem 1rem;top:35px;height: 380px!important;overflow: scroll;scroll-padding: 0px!important;padding-right: 0;" v-if="resultShowBund" cols="12">
                                                    <b-list-group>
                                                        <b-list-group-item @click="addBundle(item.bundle_id, item.name, item.bundPrice, item.originalPrice)" v-for="(item,index) in productsSRBund">
                                                            <feather-icon icon="" class="mr-75" />
                                                            <p style="font-weight: 600;"> {{item.name}}</p>
                                                            <small style="padding-left: 10px;">Bundle Price: {{item.bundPrice}}</small>
                                                            <small style="padding-left: 10px;">Original Price: {{item.originalPrice}}</small>
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-col>
                                                <b-col cols="12">
                                                    <b-row>
                                                        <b-col v-if="couponData.bundles.length" class="pt-1 pb-1 pr-5 pl-5" cols="12">
                                                            <h2 v-if="couponData.bundles.length" class="mr-3 pt-2">Items List:</h2>
                                                            <b-row v-if="couponData.bundles.length" v-for="(item, index) in couponData.bundles">
                                                                <b-col cols="12">
                                                                    <hr>
                                                                </b-col>
                                                                <b-col class="pl-5" cols="7">
                                                                    <h4>{{item.name}}</h4>
                                                                    <small style="padding-left: 10px;">Bundle Price: {{item.bundPrice}}</small>
                                                                    <small style="padding-left: 10px;">Original Price: {{item.originalPrice}}</small>
                                                                </b-col>
                                                                <b-col md="3" >
                                                                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-1" @click="removeItemBundle(index)">
                                                                        <feather-icon icon="XIcon" class="mr-25" />
                                                                        <span>Remove</span>
                                                                    </b-button>
                                                                </b-col>
                                                                <b-col cols="12">
                                                                    <hr>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col> -->
      </b-row>
      <b-card>
        <b-col>
          <b-button
            block
            size="lg"
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
        </b-col>
      </b-card>
    </validation-observer>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
export default {
  components: {
    BCardCode,
    flatPickr,
  },
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      activeSelect: {
        text: '',
        value: '',
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      id: 0,
      productsSR: [],
      productsSRBund: [],
      noResult: false,
      noResultBund: false,
      resultShow: false,
      resultShowBund: false,
      searchText: '',
      searchText2: '',
      couponData: {
        startdate: '',
        enddate: '',
        startprice: '',
        endprice: '',
        active: '',
        type: '',
        usernumbers: '',
        amount: '',
        max_amount: '',
        include: '',
        code: '',
        bundles: [],
        variants: [],
      },
      errors_back: [],
      required,
      integer,
      typeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      typeSelect: {
        text: '',
        value: '',
      },
      includeOptions: [
        {
          text: 'Exploade Selected Items',
          value: '1',
        },
        {
          text: 'Include Selected Items',
          value: '0',
        },
        {
          text: 'include All Items',
          value: '2',
        },
      ],
      includeSelect: {
        text: '',
        value: '',
      },
      showVandB: false,
      includedVlaue: false,
    }
  },
  watch: {
    includeSelect: function () {
      if (this.includeSelect.value == '1') {
        this.showVandB = true
        this.includedVlaue = true
      } else if (this.includeSelect.value == '0') {
        this.showVandB = true
        this.includedVlaue = false
      } else {
        this.showVandB = false
        this.couponData.bundles = []
        this.couponData.variants = []
      }
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.couponData.active = this.activeSelect.value
          for (let trn in this.couponData.translations) {
            this.couponData.translations[trn].locale = this.tranLocaleSelect[
              trn
            ].value
          }
          this.couponData.type = this.typeSelect.value
          var data = this.couponData
          if (this.includeSelect.Text == 'include All Items') {
            delete data.include
          } else {
            data.include = this.includeSelect.value
          }
          if (this.couponData.code == '') {
            delete data.code
          }
          const variants = []
          for (let index in this.couponData.variants) {
            variants.push(this.couponData.variants[index].variant_id)
          }
          const bundles = []
          for (let index in this.couponData.bundles) {
            bundles.push(this.couponData.bundles[index].bundle_id)
          }
          data.variants = variants
          data.bundles = bundles

          axios
            .put('coupons/' + this.id, data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(0)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    closeOpenSearch() {
      if (this.resultShow == true || this.resultShowBund == true) {
        this.resultShow = false
        this.resultShowBund = false
      }
    },
    searchResult(text) {
      this.noResult = false

      axios
        .get('variants/index/' + text)
        .then((result) => {
          const data = result.data.data.data

          this.productsSR.length = 0
          for (let index in data) {
            if (data[index].translation != null) {
              this.productsSR.push({
                id: data[index].id,
                name: data[index].translation.name,
                price: data[index].sale_price,
              })
            } else {
              this.productsSR.push({
                id: data[index].id,
                name: 'undefinded',
                price: data[index].sale_price,
              })
            }
          }
          if (this.productsSR.length > 0) {
            this.resultShow = true
          } else {
            this.noResult = true
            this.resultShow = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResultBundle(text) {
      this.noResultBund = false

      axios
        .get('bundles/index/' + text)
        .then((result) => {
          const data = result.data.data.data

          this.productsSRBund.length = 0
          for (let index in data) {
            this.productsSRBund.push({
              bundle_id: data[index].id,
              name: data[index].title,
              bundPrice: data[index].bundle_price,
              originalPrice: data[index].original_price,
            })
          }

          if (this.productsSRBund.length > 0) {
            this.resultShowBund = true
          } else {
            this.noResultBund = true
            this.resultShowBund = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    addProduct(id, name, price) {
      this.resultShow = false
      const found = this.couponData.variants.some((el) => el.variant_id === id)

      if (!found) {
        this.couponData.variants.push({
          variant_id: id,
          price: price,
          name: name,
        })
      }
    },
    addBundle(id, name, bundPrice, originalPrice) {
      this.resultShowBund = false
      const found = this.couponData.bundles.some((el) => el.bundle_id === id)

      if (!found) {
        this.couponData.bundles.push({
          bundle_id: id,
          bundPrice: bundPrice,
          originalPrice: originalPrice,
          name: name,
        })
      }
    },
    removeItemProduct(index) {
      this.couponData.variants.splice(index, 1)
    },
    removeItemBundle(index) {
      this.couponData.bundles.splice(index, 1)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.id = this.$route.params.id
    axios
      .get('coupons/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.couponData.active = data.active
        this.couponData.type = data.type
        this.couponData.startdate = data.startdate
        this.couponData.enddate = data.enddate
        this.couponData.startprice = data.startprice
        this.couponData.endprice = data.endprice
        this.couponData.usernumbers = data.usernumbers
        this.couponData.amount = data.amount
        this.couponData.max_amount = data.max_amount
        this.couponData.include = data.include
        this.couponData.code = data.code
        if (data.active == true) {
          this.activeSelect.text = 'True'
          this.activeSelect.value = '1'
        } else {
          this.activeSelect.text = 'False'
          this.activeSelect.value = '0'
        }
        //          watch: {

        //     "includeSelect": function() {
        //         if (this.includeSelect.value == "1") {
        //             this.showVandB = true
        //             this.includedVlaue = true
        //         }
        //         else if (this.includeSelect.value == "0"){
        //             this.showVandB = true
        //             this.includedVlaue = false
        //         }
        //         else{
        //             this.showVandB = false
        //             this.couponData.bundles = []
        //             this.couponData.variants = []
        //         }
        //     },
        // },
        if (data.variants.length == 0 && data.bundles.length == 0) {
          this.includeSelect.text = 'include All Items'
          this.includeSelect.value = '2'
          this.showVandB = false
        } else {
          this.showVandB = true
          if (data.include == true) {
            this.includeSelect.text = 'Exploade Selected Items'
            this.includeSelect.value = '1'
            this.includedVlaue = true
          } else {
            this.includeSelect.text = 'Include Selected Items'
            this.includeSelect.value = '0'
            this.includedVlaue = false
          }
        }
        if (data.type == 'fixed_price') {
          this.typeSelect.text = 'Fixed Price'
          this.typeSelect.value = 'fixed_price'
        } else {
          this.typeSelect.text = 'Percentage'
          this.typeSelect.value = 'percentage'
        }
        if (data.variants.length > 0) {
          for (let index in data.variants) {
            const varia = data.variants[index]
            this.couponData.variants.push({
              name: varia.name,
              variant_id: varia.id,
              price: varia.sale_price,
            })
          }
        }
        if (data.bundles.length > 0) {
          for (let index in data.bundles) {
            const bund = data.bundles[index]
            this.couponData.bundles.push({
              bundle_id: bund.id,
              bundPrice: bund.price,
              originalPrice: bund.original_price,
              name: bund.title,
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>

<style lang="scss">
</style>
